.MuiIconButton-label {
    background-color: white;
    opacity: 1;
    margin-left: 75px;
    margin-top: -25px;
}
.PrivateSwitchBase-input-8 {
    background-color: white;
    opacity: 0.5;
    margin-left: 75px;
    margin-top: -25px;
}

.MuiButtonBase-root, .MuiIconButton-root, .PrivateSwitchBase-root-1, 
.MuiCheckbox-root, .MuiCheckbox-colorSecondary, .PrivateSwitchBase-checked-2, 
.Mui-checked, .MuiIconButton-colorSecondary {
    background-color: white;
    opacity: 0.5;
    margin-left: 75px;
    margin-top: -25px;
}



/* #FavChecked {
    margin-top: 10px;
} */


.Mui-checked {
    margin-top: 10px;
}

/* .talent-container {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
} */

/* .talent-container[_ngcontent-jww-c28] {
    max-width: 244px;
    margin-bottom: 15px;
}

.clickable {
    cursor: pointer;
    outline: 0!important;
} */

.padding-element-content {

}

.favorite {
    margin-left: 10%;
}

.IconFav {
    color: white;
}

.MuiSvgIcon-root {
    background-color: black;
}