.review-from {
    font-size: 16px;
    color: #32325d;
    font-family: Lato-Bold!important;
    font-weight: 700;
}

.messageReview {
    width: 65%;
}

.linkToReviewsList {
    color: #6948f9;
    font-size: 18px;
}