.myradio {
    -webkit-appearance: none;
}

.mylabel {
    height: 80px;
    width: 90px;
    /*border: 6px solid #DE73FF;*/
    margin: auto;
    border-radius: 5px;
    position: relative;
    color: #DE73FF;
    transition: 0.5s;
    overflow: hidden;
    white-space: nowrap;
}

.icone {
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
}

.mylabel > span {
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 80%);
}

.myradio:checked + .mylabel {
    background-color: #DE73FF;
    color: #ffffff;
    box-shadow: 0 15px 45px rgb(24, 249, 141, 0.2);
}
